/* eslint-disable no-useless-escape */
export const debounce = (func, wait, immediate) => {
    let timeout;
    return function() {
        let context = this, args = arguments;
        let later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    }
}

export const throttle = (callback, limit) => {
    let wait = false;
    return function() {
        if (!wait) {
            callback.apply(null, arguments);
            wait = true;
            setTimeout(function() {
                wait = false;
            }, limit);
        }
    }
}

export const lead_zero = n => n < 10 ? '0' + n : n;

export const checkMobileSize = () => window.matchMedia('(max-width: 740px)').matches;

export const checkMobile = () => {
    const check = a => (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)));
    return check(navigator.userAgent || navigator.vendor || window.opera);
}

export class UniformRandom {
    constructor(range) {
        this.mutationSeed  = new Uint32Array(31).fill(null); // Random seed values control order of mutations
        this.sequenceIndex = 0; // Index in the random sequence
        this.maxBitIndex   = 0; // Index of the highest bit required to represent maxValue (eg. maxValue is 11, maxBitIndex is 3)
        this.maxValue      = 0; // Highest value in the random sequence
        this.bitMask       = 0; // Bitmask whose length is maxBitIndex+1  (eg. maxBitIndex is 3, bitMask is 0b1111)
        this.numBits       = 0; // Number of bits in bitMask (basically maxBitIndex+1; to prevent adding 1 repeatedly elsewhere)
        this.numMaskValues = 0; // Number of values that can be repesented by numBits bits
        this.randomize(range);
    }

    reset() {
        this.sequenceIndex = 0;
        crypto.getRandomValues(this.mutationSeed);
    }

    generate(forward) {
        let result = this.maxValue;
        for (let i = 0; (i < this.numMaskValues) && (result >= this.maxValue); ++i) {
            this.sequenceIndex = (this.sequenceIndex + (forward?1:-1)) % this.numMaskValues;
            result = this.sequenceIndex;
            for (let j = 0; j < this.mutationSeed.length; ++j) {
                result = (((result << 1) & this.bitMask) | ((result >>> (this.numBits - 1)) & this.bitMask));
                let seedInt = this.mutationSeed[j];
                for (let k = 0; k < this.maxBitIndex; ++k) {
                    const opArg = (seedInt + j + k);
                    switch (seedInt & 3) {
                        case 0:{
                            let reversedBits = 0;
                            for(let bitIndex = this.numBits; bitIndex--;)
                                    reversedBits |= ((result>>>(this.maxBitIndex-bitIndex))&1)<<bitIndex;
                            result = reversedBits;
                            break;
                        }
                        case 1: {
                            let n = opArg%this.maxBitIndex;
                            result = (((result << n) & this.bitMask) | ((result >>> (this.numBits - n)) & this.bitMask));
                            break;
                        }
                        case 2:
                            result = (result + opArg) & this.bitMask;
                            break;
                        case 3:
                            result = (result ^ opArg) & this.bitMask;
                            break;
                    }
                    seedInt >>>= 1;
                }
            }
        }
        return result;
    }

    randomize(range) {
        this.maxValue = range;
        this.numMaskValues  = range - 1;
        this.numMaskValues |= this.numMaskValues >> 1;
        this.numMaskValues |= this.numMaskValues >> 2;
        this.numMaskValues |= this.numMaskValues >> 4;
        this.numMaskValues |= this.numMaskValues >> 8;
        this.numMaskValues |= this.numMaskValues >> 16;
        this.numMaskValues++;
        this.bitMask = this.numMaskValues - 1;
        this.maxBitIndex = 32;

        let v = this.numMaskValues & -this.numMaskValues;
        if (v) this.maxBitIndex--;
        if (v & 0x0000FFFF) this.maxBitIndex -= 16;
        if (v & 0x00FF00FF) this.maxBitIndex -= 8;
        if (v & 0x0F0F0F0F) this.maxBitIndex -= 4;
        if (v & 0x33333333) this.maxBitIndex -= 2;
        if (v & 0x55555555) this.maxBitIndex -= 1;
        this.maxBitIndex--;
        this.numBits = this.maxBitIndex + 1;

        this.reset();
    }

    next(){return this.generate(true);}
    prev(){return this.generate(false);}
}
