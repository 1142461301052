/* global data_json */
/* eslint no-unused-vars: 1 */
import 'regenerator-runtime/runtime'; // Allows async functions
import { UniformRandom, throttle, debounce, checkMobileSize, lead_zero } from '../js/util.js';
import { update, removeAll, Tween, Easing } from 'es6-tween'
// import * as TWEEN from '@tweenjs';
import * as PIXI from 'pixi.js';
import SmoothScroll from 'smooth-scroll';
import * as THREE from 'three'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2.js';
import { MtlObjBridge } from 'three/examples/jsm/loaders/obj2/bridge/MtlObjBridge.js';
import { tns } from 'tiny-slider/src/tiny-slider';
import SimpleBar from 'simplebar';
import FOG from 'vanta/dist/vanta.fog.min'

PIXI.utils.skipHello();

const URLPrefix = process.env.NODE_ENV === 'production' || !process.env.NODE_ENV ? 'https://lovebook.mwm.partners' : ''

const header = () => {
    const calcVH = () => {
        requestAnimationFrame(() => {
            var vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', vh + 'px');  // 70 = DE menu height
        });
    };

    calcVH();

    window.addEventListener('resize',debounce(() => calcVH(), 200));
};

const photoCollage = () => {
    const section = document.querySelector('section.photo_collage');
    const faces = [];
    const faceSprites = [];

    for (const item of data_json.collage_items) {
        faces.push(item.image);
    }

    let screen_width;
    let screen_height;

    if (window.matchMedia('(orientation: landscape)').matches) {
        screen_width = 1500;
        screen_height = 1000;
    } else {
        screen_width = 768;
        screen_height = 1024;
    }

    const pixi = new PIXI.Application({
        width: screen_width,
        height: screen_height,
        antialias: false,
        transparent: true
    });

    pixi.renderer.resize(screen_width, window.innerHeight);

    const stage = pixi.stage;
    const gfxShape = new PIXI.Graphics();
    const faceContainer = new PIXI.Container();
    let timeout = false;

    section.appendChild(pixi.renderer.view);
    pixi.stage.addChild(faceContainer);
    pixi.loader.add(faces);

    pixi.ticker.add(dt => update(pixi.ticker.lastTime));

    pixi.loader.load((loader, resources) => {
        let tween;
        const NUMBER_OF_SETS = 8;
        let uniRnd = new UniformRandom(faces.length*NUMBER_OF_SETS);

        // Add 8 sprites of each portrait so we have enough to fill the screen
        for (let i = 0; i<NUMBER_OF_SETS; i++) {
            for (const face of faces) {
                let sprite = new PIXI.Sprite.from(face);
                sprite.alpha = 0;
                if (checkMobileSize()) {
                    sprite.scale.x = 0.6;
                    sprite.scale.y = 0.6;
                }
                sprite.anchor.set(0.5)
                faceSprites.push(sprite);
            }
        }

        let scaleFactor = innerWidth/screen_width;
        let containerSize = section.getBoundingClientRect();

        let backtotop = (sprite) => {
            if (timeout) return;
            update()

            sprite.alpha = 0;
            faceContainer.removeChild(sprite);
            faceContainer.addChild(sprite);
            tween = new Tween(sprite).to({
                alpha: 1
            }, 2000).on('complete', () => {
                setTimeout(backtotop, 46000, sprite);
            }).easing(Easing.Exponential.InOut).start();
        }

        for (const sprite of faceSprites) {
            faceContainer.addChild(sprite);
            setTimeout(() => {
                backtotop(sprite);
            },(uniRnd.next()/(faces.length*NUMBER_OF_SETS))*48000);
            sprite.position.set((1/scaleFactor)*Math.random()*containerSize.width, (1/scaleFactor)*Math.random()*containerSize.height);
        }

        stage.addChild(gfxShape);

        setTimeout(() => {
            timeout = true;
        }, 60000);
    });

    const page_arrow = section.querySelector('.page-arrow');

    new SmoothScroll('a[href*="#first-section"]', {
        speed: 1500,
        updateURL: false,
        popstate: false,
        emitEvents: false,
        easing: 'easeInOutQuart'
    });

    const options = {
        root: null,
        rootMargin: '0% 0% 0% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    page_arrow.classList.add('active');
                }, 1);
            } else {
                setTimeout(() => {
                    page_arrow.classList.remove('active');
                }, 1);
            }

        }
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    observer.observe(page_arrow);
};

const instagramShapes = () => {
    if (checkMobileSize()) return;

    const section = document.querySelector('section.instagram');
    let canvas_wrap;
    let camera, scene, renderer;
    let shapes = [];
    const total_shapes = 10;
    const canvas_height = 2000;
    let in_view = false;
    let center_offset;

    canvas_wrap = document.createElement('div');
    canvas_wrap.classList.add('canvas-wrap');
    section.appendChild(canvas_wrap);
    camera = new THREE.PerspectiveCamera(1, window.innerWidth / canvas_height, 1, 1000);

    scene = new THREE.Scene();
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);
    directionalLight.position.x = 10;
    directionalLight.position.y = 10;
    scene.add(directionalLight);
    const pointLight = new THREE.AmbientLight(0xFF0000, 1.8);
    scene.add(pointLight);
    scene.add(camera);

    if (window.matchMedia('(max-width: 1040px)').matches) {
        center_offset = 1.3;
    } else {
        center_offset = 2;
    }

    for (let i = 0; i < total_shapes; i++) {
        {
            // Create O Geometry
            // Add O to shapes list
            const mtlLoader = new MTLLoader();
            mtlLoader.load(`${URLPrefix}/static/models/ring.mtl`, (mtlParseResult) => {
                const objLoader = new OBJLoader2();
                const materials =  MtlObjBridge.addMaterialsFromMtlLoader(mtlParseResult);
                objLoader.addMaterials(materials);
                objLoader.load(`${URLPrefix}/static/models/ring.obj`, (root) => {
                    scene.add(root);
                    shapes.push(root);
                    // Save initial y offset
                    root.position._y = -5 - 2.5*i - ((i&1)?0:1);
                    root.position.x = center_offset * ((i&1)?-1:1);
                    // Randomize starting rotation
                    root._x = root.rotation.x = Math.random()*Math.PI;
                    root._y = root.rotation.y = Math.random()*Math.PI;
                });
            });
        }
        {
            // Create X Geometry
            // Add X to shapes list
            const mtlLoader = new MTLLoader();
            mtlLoader.load(`${URLPrefix}/static/models/cross.mtl`, (mtlParseResult) => {
                const objLoader = new OBJLoader2();
                const materials =  MtlObjBridge.addMaterialsFromMtlLoader(mtlParseResult);
                objLoader.addMaterials(materials);
                objLoader.load(`${URLPrefix}/static/models/cross.obj`, (root) => {
                    scene.add(root);
                    shapes.push(root);
                    // Save initial y offset
                    root.position._y = -5 - 2.5*i - ((i&1)?1:0);
                    root.position.x = center_offset * ((i&1)?1:-1);
                    // Randomize starting rotation
                    root._x = root.rotation.x = Math.random()*Math.PI;
                    root._y = root.rotation.y = Math.random()*Math.PI;
                });
            });
        }
    }

    renderer = new THREE.WebGLRenderer({alpha: true, antialias: true });
    renderer.setClearColor(0x000000, 0);
    renderer.setPixelRatio(window.devicePixelRatio);
    canvas_wrap.appendChild(renderer.domElement);

    for (let shape of shapes) {
        shape.position.y = shape.position._y + scroll_with_page;
    }

    const render = (options) => {
        if (!in_view) return;
        const scroll_with_page = 8.6; //(pageYOffset/215 + shape.position._y + innerHeight/425) - 4.5

        if (options.scrolled) removeAll();

        for (let shape of shapes) {
            shape.position.y = shape.position._y + scroll_with_page;

            if (options.scrolled) {
                const tween = new Tween(shape.rotation).to({
                    x: (pageYOffset*.005/2 + shape._x) % (Math.PI*2),
                    y: (pageYOffset*.005/2 + shape._y) % (Math.PI*2)
                }, 1000).easing(Easing.Exponential.InOut).start();
            } else {
                shape.rotation.y += .001;
            }
        }

        renderer.render(scene, camera);
    };

    const animate = time => {
        requestAnimationFrame(animate);
        render({'scrolled':false});
        update(time);
    };
    animate();

    window.addEventListener('scroll', debounce(() => {
        if (!in_view) return;
        requestAnimationFrame(() => render({'scrolled':true}));
    }, 100));

    window.addEventListener('resize',debounce(() => {
        const canvas_offset = section.querySelector('.section-title').offsetHeight;
        canvas_wrap.style.top = `${canvas_offset}px`;
        camera.position.z = 400 * canvas_height/1500;
        camera.aspect = window.innerWidth / canvas_height;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, canvas_height);
    }, 100));

    const options = {
        root: null,
        rootMargin: '0% 0% 0% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    in_view = true;
                }, 1);
            } else {
                setTimeout(() => {
                    in_view = false;
                }, 1);
            }
        }
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    observer.observe(section);
};

const instagramPhotos = () => {
    const section = document.querySelector('section.instagram .stories');

    let in_view;

    const updatePerspective = (obj) => {
        let target = obj.target,
            target_inner = obj.target.querySelector('.elem-3d-inner'),
            window_width = window.innerWidth,
            window_height = window.innerHeight,
            elem_weight = target_inner.offsetWidth,
            elem_height = target_inner.offsetHeight,
            perspective = (elem_weight/window_width)*100*10;

        target.style.perspective = `${perspective}px`;
    };

    const updateRotation = (obj) => {
        let target = obj.target,
            target_inner = obj.target.querySelector('.elem-3d-inner'),
            coord_x = obj.coords.x,
            coord_y = obj.coords.y,
            window_width = window.innerWidth,
            window_height = window.innerHeight,
            elem_weight = target_inner.offsetWidth,
            elem_height = target_inner.offsetHeight,
            perspective = (elem_weight/window_width)*100*10;

        let rotation = {
            x: Math.round(((coord_x - window_width / 2) * .01) * 1e2) / 1e2,
            y: Math.round((-1 * (coord_y - window_height / 2) * .01) * 1e2) / 1e2
        };

        if (rotation.x <= -1) {
            rotation.x = -1;
        } else if (rotation.x >= 1) {
            rotation.x = 1;
        }

        if (rotation.y <= -1) {
            rotation.y = -1;
        } else if (rotation.y >= 1) {
            rotation.y = 1;
        }

        target_inner.style.transform = `rotateY(${rotation.x}deg) rotateX(${rotation.y}deg)`;
    };

    const elem_3d = document.querySelectorAll('.elem-3d');

    window.addEventListener('resize',debounce((e) => {
        for (const elem of elem_3d) {
            updatePerspective({
                target: elem
            });
        }
    }, 200));

    window.addEventListener('mousemove',throttle((e) => {
        if (!in_view) return;
        for (const elem of elem_3d) {
            updateRotation({
                target: elem,
                coords: {
                    x: e.x,
                    y: e.y
                }
            });
        }
    }, 50));

    window.dispatchEvent(new Event('resize'));

    const options = {
        root: null,
        rootMargin: '0% 0% 0% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    in_view = true;
                }, 1);
            } else {
                setTimeout(() => {
                    in_view = false;
                }, 1);
            }
        }
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    observer.observe(section);
};

const instagramWaves = () => {
    const svg = document.querySelector('section.instagram svg.waves');

    if (!svg) return;

    const path = svg.querySelector('path');
    const m = 0.512286623256592433;

    const buildWave = (w, h) => {
        const a = h / 4;
        const y = h / 2;

        const pathData = [
        'M', w * 0, y + a / 2,
        'c',
        a * m, 0,
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,

        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a,
        's',
        -(1 - a) * m, a,
        a, a,
        's',
        -(1 - a) * m, -a,
        a, -a
        ].join(' ');

        path.setAttribute('d', pathData);
    };

    buildWave(80, 60);

    const options = {
        root: null,
        rootMargin: '0% 0% 0% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    svg.classList.add('active');
                }, 1);
            } else {
                setTimeout(() => {
                    svg.classList.remove('active');
                }, 1);
            }
        }
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    observer.observe(svg);
};

const instagramStories = () => {
    const section = document.querySelector('section.instagram .stories');

    if (!section) return;

    document.body.insertAdjacentHTML('beforeend', `
        <div class="instagram-modal">
            <div class="wrap">
                <div class="bubble-outer">
                <div class="bubble">
                    <div class="close" aria-label="close"></div>
                    <div class="img"></div>
                    <div class="name"></div>
                    <div class="body"></div>
                </div>
                </div>
            </div>
        </div>
    `);

    const instagram_modal = document.querySelector('.instagram-modal');
    const name = instagram_modal.querySelector('.name');
    const body = instagram_modal.querySelector('.body');

    instagram_modal.addEventListener('click', (e) => {
        if (e.target.classList.contains('instagram-modal') || e.target.classList.contains('close')) {
            instagram_modal.classList.remove('active');
        }
    });

    let items_per_row = 8;
    let row_index = 0;
    let item_index = 0;

    for (let i = 0; i < Math.ceil(data_json.instagram_items.length/items_per_row); i++) {
        const even_odd = i % 2 ? 'even' : 'odd';
        section.insertAdjacentHTML('beforeend', `<div class="row ${even_odd}"></div>`);
    }

    for (const [index, item] of data_json.instagram_items.entries()) {
        row_index = (index) % items_per_row === 0 ? row_index+1 : row_index;
        item_index = (item_index) % items_per_row === 0 ? 0 : item_index;

        section.querySelector(`.row:nth-child(${row_index})`).insertAdjacentHTML('beforeend', `
        <div class="story story-${item_index+1} elem-3d" data-instagram-index="${index}">
            <div class="elem-3d-inner">
                <div class="img" style="background-image:url(${item.image});"></div>
            </div>
        </div>
        `);

        item_index += 1;
    }

    for (const story of section.querySelectorAll('.story')) {
        story.addEventListener('click', e => {
            let i = story.getAttribute('data-instagram-index');
            if (!i) i = story.getAttribute('data-instagram-index');
            const instagramItem = data_json.instagram_items[i];
            name.innerHTML = instagramItem.name;
            body.innerHTML = instagramItem.body;
            instagram_modal.classList.add('active');
        });
    }
};

const storiesCarousel = () => {
    const sections = document.querySelectorAll('section.stories-carousel');

    if (!sections) return;

    data_json.story_items.forEach((e, i) => {
        const item = document.querySelector(`[data-story-index="${i}"]`);
        const number = lead_zero(i+1);
        const no_image = e.image ? `` : `no_image`;
        const image = e.image ? `<div class="image"><div style="background-image:url(${e.image});"></div></div>` : ``;
        const graphic = e.graphic_1 ? `<div class="graphic ${no_image}"><div style="background-image:url(${e.graphic_1});"></div></div>` : ``;

        item.insertAdjacentHTML('beforeend', `
            <div class="wrap">
                <div class="panel">
                    ${graphic}
                    <div class="row">
                        ${image}
                        <div class="info">
                            <div class="number">${number}</div>
                            <div class="name">${e.name}</div>
                            <div class="indent">
                                <div class="quote">${e.blockquote}</div>
                                <div class="btn" role="button">Read More</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `);
    });

    document.body.insertAdjacentHTML('beforeend', `
        <div class="story-modal">
            <div class="wrap">
                <div class="close" aria-label="close"></div>
                <div class="scroll">
                    <div class="graphic"></div>
                    <div class="number"></div>
                    <div class="name"></div>
                    <div class="intro"></div>
                    <div class="quote"></div>
                    <div class="outro"></div>
                </div>
            </div>
        </div>
    `);

    const story_modal = document.querySelector('.story-modal');
    const graphic = story_modal.querySelector('.graphic');
    const number = story_modal.querySelector('.number');
    const name = story_modal.querySelector('.name');
    const intro = story_modal.querySelector('.intro');
    const quote = story_modal.querySelector('.quote');
    const outro = story_modal.querySelector('.outro');

    const options = {
        root: null,
        rootMargin: '0% 0% 0% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    entry.target.classList.add('active');
                }, 1);
            } else {
                setTimeout(() => {
                    entry.target.classList.remove('active');
                }, 1);
            }
        }
    };

    story_modal.addEventListener('click', (e) => {
        if (e.target.classList.contains('story-modal') || e.target.classList.contains('close')) {
            story_modal.classList.remove('active');
        }
    });

    for (const section of sections) {
        const slider = tns({
            container: section.querySelector('.carousel'),
            items: 1,
            slideBy: 'page',
            autoplay: false,
            speed: 300,
            loop: false,
            nav: false,
            autoHeight: checkMobileSize() ? true : false,
            controls: false,
            mouseDrag: true,
        });

        slider.events.on('indexChanged', (e) => {
            section.querySelector('.dots .heart').style.transform = `translate3d(${e.index * 100}%,0,0)`;

            for (const [index, dot] of section.querySelectorAll('.dots .dot').entries()) {
                if (e.index === index) {
                    dot.classList.add('active');
                } else {
                    dot.classList.remove('active');
                }
            }

            if (e.index === 0) {
                section.querySelector('.controls .next').classList.remove('disabled');
                section.querySelector('.controls .prev').classList.add('disabled');
            } else if (e.index === e.slideCount-1) {
                section.querySelector('.controls .prev').classList.remove('disabled');
                section.querySelector('.controls .next').classList.add('disabled');
            } else {
                section.querySelector('.controls .prev').classList.remove('disabled');
                section.querySelector('.controls .next').classList.remove('disabled');
            }
        });

        section.querySelector('.controls .prev').addEventListener('click', () => {
            slider.goTo('prev');
        });

        section.querySelector('.controls .next').addEventListener('click', () => {
            slider.goTo('next');
        });

        for (const btn of section.querySelectorAll('.carousel .btn')) {
            btn.addEventListener('click', e => {
                let i = e.target.closest('.item').getAttribute('data-story-index');
                const storyItem = data_json.story_items[i];

                if (storyItem.graphic_2) {
                    graphic.innerHTML= '';
                    graphic.insertAdjacentHTML('beforeend', `
                        <div style="background-image:url(${storyItem.graphic_2});"></div>
                    `);
                    graphic.classList.add('active');
                } else {
                    graphic.innerHTML= '';
                    graphic.classList.remove('active');
                }

                number.innerHTML = `0${parseInt(i) + 1}`;
                name.innerHTML = storyItem.name;
                intro.innerHTML = storyItem.intro;
                quote.innerHTML = storyItem.blockquote;
                outro.innerHTML = storyItem.outro;
                story_modal.classList.add('active');
            });
        }

        for (const [index, dot] of section.querySelectorAll('.dots .dot').entries()) {
            dot.addEventListener('click', () => {
                slider.goTo(index);
            });
        }

        const observer = new IntersectionObserver(handleIntersect, options);

        observer.observe(section);
    }
};

const messageBubbles = () => {
    const section = document.querySelector('section.message-bubbles');

    if (!section) return;

    const bubbles = section.querySelectorAll('.bubble');

    const calcBubbleHeight = (bubble) => {
        let height;

        if (checkMobileSize()) {
            height = bubble.querySelector('.text > div').scrollHeight + 0;
        } else {
            height = bubble.querySelector('.text > div').scrollHeight + 3;
        }

        requestAnimationFrame(() => {
            bubble.querySelector('.text').style.height = `${height}px`;
            bubble.classList.add('active');
        });
    };

    const calcBubbleOffset = (bubble) => {
        const offset = bubble.getBoundingClientRect().left;
        const bubble_width = window.innerWidth - 120 + 21 + 21

        if (bubble.classList.contains('flip')) {
            if (offset < bubble_width) {
                requestAnimationFrame(() => {
                    bubble.style.transform = `translate3d(${bubble_width - offset + 20}px,0,0)`;
                });
            }
        } else {
            if (offset+bubble_width > window.innerWidth) {
                requestAnimationFrame(() => {
                    bubble.style.transform = `translate3d(-${offset - 30}px,0,0)`;
                });
            }
        }
    };

    const randomize = () => {
        const max = 9;
        const random = [];
        let position = 0;

        for (var i = 0; i<max; i++) {
            const temp = Math.floor(Math.random()*max);
            if (random.indexOf(temp) == -1) {
                random.push(temp);
            } else {
                i--;
            }
        }

        const openBubble = () => {
            for (const bubble of bubbles) {
                if (bubble.classList.contains(`bubble-${random[position]+1}`)) {
                    calcBubbleHeight(bubble);
                    calcBubbleOffset(bubble);
                } else {
                    requestAnimationFrame(() => {
                        bubble.querySelector('.text').style.height = ``;
                        bubble.style.transform = ``;
                        bubble.classList.remove('active');
                    });
                }
            }

            if (position === max-1) {
                position = 0;
            } else {
                position = position+1;
            }
        };

        setTimeout(() => {
            openBubble();
            setInterval(() => {
                openBubble();
            }, 3000);
        }, 600);
    };

    for (const bubble of bubbles) {
        bubble.addEventListener('mouseover', () => {
            if (bubble.classList.contains('active') || checkMobileSize()) return;

            calcBubbleHeight(bubble);
        });

        bubble.addEventListener('mouseleave', () => {
            if (bubble.classList.contains('open') || checkMobileSize()) return;

            requestAnimationFrame(() => {
                bubble.querySelector('.text').style.height = ``;
                bubble.style.transform = ``;
                bubble.classList.remove('active');
            });
        });
    }

    const options = {
        root: null,
        rootMargin: '0% 0% -75% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    section.classList.add('active');
                    if (checkMobileSize()) {
                        randomize();
                    } else {
                        /*
                        for (const bubble of section.querySelectorAll('.bubble.open')) {
                            setTimeout(() => {
                                calcBubbleHeight(bubble);
                            }, 300);
                        }
                        */
                    }
                    observer.unobserve(entry.target);
                }, 1);
            }
        }
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    observer.observe(section);
};

const emailScroll = () => {
    const sections = document.querySelectorAll('section.email');

    const options = {
        root: null,
        rootMargin: '0% 0% -75% 0%',
        threshold: 0
    };

    const options2 = {
        root: null,
        rootMargin: '0% 0% 0% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    entry.target.classList.add('animate');
                    observer.unobserve(entry.target);
                }, 1);
            }
        }
    };

    const handleIntersect2 = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    entry.target.classList.add('active');
                }, 1);
            } else {
                setTimeout(() => {
                    entry.target.classList.remove('active');
                }, 1);
            }
        }
    };

    for (const section of sections) {
        new SimpleBar(section.querySelector('.scroll'), {autoHide: false});

        let observer = new IntersectionObserver(handleIntersect, options);
        observer.observe(section);

        observer = new IntersectionObserver(handleIntersect2, options2);
        observer.observe(section);
    }
};

const textLineAnimate = () => {
    const sections = document.querySelectorAll('section.instagram, section.blockquote, section.vanta-fog');

    const options = {
        root: null,
        rootMargin: '0% 0% -75% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                const delay = parseInt(entry.target.getAttribute('data-delay'));
                setTimeout(() => {
                    entry.target.classList.add('animate');

                    setTimeout(() => {
                        entry.target.classList.add('change-color');
                    }, delay);

                    observer.unobserve(entry.target);
                }, 1);
            }
        }
    };

    for (const section of sections) {
        const items = section.querySelectorAll('.section-title span, .quote span, .spacer, .name, .text span, .sig, .backtotop');

        section.setAttribute('data-delay',((items.length+3) * .1) * 1000);

        for (const [index, item] of items.entries()) {
            item.style.transitionDelay = `${index * .1}s`;
        }

        const observer = new IntersectionObserver(handleIntersect, options);
        observer.observe(section);
    }
};

const vantaFog = () => {
    const section = document.querySelector('section.vanta-fog');

    if (!section) return;

    const backtotop = new SmoothScroll('a[href*="#backtotop"]', {
        speed: 200,
        updateURL: false,
        popstate: false,
        emitEvents: false,
        easing: 'easeInOutQuart'
    });

    let fog;

    const options = {
        root: null,
        rootMargin: '0% 0% 50% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    fog = FOG({
                        el: section,
                        THREE: THREE,
                        highlightColor: 0xff2b2b,
                        midtoneColor: 0xffc3f0,
                        lowlightColor: 0x3c00ff,
                        baseColor: 0xffdaf0,
                        blurFactor: .49,
                        speed: 1.80,
                        zoom: .70
                    });
                }, 1);
            } else {
                setTimeout(() => fog ? fog.destroy() : '', 1);
            }
        }
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    observer.observe(section);
};

const loadContent = () => {
    header();
    photoCollage();
    instagramStories();
    instagramShapes();
    instagramPhotos();
    instagramWaves();
    storiesCarousel();
    messageBubbles();
    emailScroll();
    textLineAnimate();
    vantaFog();
};

const getData = async () => {
    const url = `${URLPrefix}/api/json/`;
    const data = await fetch(url).then(res => res.json()).then(res => res);
    document.querySelector('#first-section .subtitle').innerHTML = data.page.intro_title;
    document.querySelector('#first-section .richtext').innerHTML = data.page.intro;

    window.data_json = data;
    return;
};

addEventListener('DOMContentLoaded', async () => {
    if (!window.data_json) getData().then(loadContent);
    else loadContent();
});
